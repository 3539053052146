import { defineStore } from "pinia";
import { _api } from '~/endpoint';
import { ref, onMounted } from 'vue';
import axios from 'axios';
const config = useRuntimeConfig();
const host = config.public.baseURL

export const useCentreStore = defineStore('CentreStore', {
  state: () => ({
    centres: ref([]),
  }),
  getters: {
    async getCentre(state) {
         try {
          const response = await axios.get(host +_api.centre);
          return response.data;
        } catch (error) {
          console.error('error', error);
        }
   
    },
  },
  actions: {
    async addCentre(form: any) {
      const $privateApi = useNuxtApp().$privateApi;
       return await $privateApi.post(_api.centre, form);
      
    },
  },
});
